import React, {  useEffect, useContext, Suspense, useState } from "react"
import { BlocksContext } from "../context/blocksContext"
import Menu from "./menu"
import Header from "./header"
import BeSide from "./beside"

export const Loader = () => {
    import("../css/style_1601.css");
    //const [loaded, setLoaded] = useState(2)
    // ход строительства после надежно
    const loaded = useContext(BlocksContext)
    

    //const Menu = React.lazy(() => import("./menu"))
    //const Header = React.lazy(() => import("./header"))
    //const BeSide = React.lazy(() => import("./beside"))
    // const Profit = React.lazy(() => import("./profit"))
    // const Action = React.lazy(() => import("./action"))
    const Present = React.lazy(() => import("./getPresent"))
    // const Comfort = React.lazy(() => import("./comfort"))
    const PlanMap = React.lazy(() => import("./planMap"))
    const BestOffer = React.lazy(() => import("./bestOffer"))
    const Prolet = React.lazy(() => import("./prolet"))
    // const Balkon = React.lazy(() => import("./balkon"))
    const Infra = React.lazy(() => import("./infra"))
    const InfraMap = React.lazy(() => import("./infraMap"))
    const SpecialOffers = React.lazy(() => import("./specialOffers"))
    const GetPodbor = React.lazy(() => import("./getPodbor"))
    const HowToBuy = React.lazy(() => import("./howToBuy"))
    const Quality = React.lazy(() => import("./quality"))
    const Youtube = React.lazy(() => import("./youtube"))
    const Remont = React.lazy(() => import("./remont"))
    const Nadejno = React.lazy(() => import("./nadejno"))
    // const Managers = React.lazy(() => import("./managers"))
    const Contacts = React.lazy(() => import("./contacts"))
    const HodStr = React.lazy(() => import("./hodStr"))
    const Popups = React.lazy(() => import("./popups"))

    
    const LoadBlock = (block, key) => {
        return <Suspense fallback={<div></div>}>{block}</Suspense>
    }


    const blocks = [<Menu />,  <Header />, /*<Action />,*/  <BeSide />, /* <Profit />,*/ <Present />, /*<Comfort />*/, <PlanMap />, <BestOffer />, <Prolet />, /*<Balkon />,*/ <Infra />, <InfraMap />, <SpecialOffers />, <GetPodbor />, <HowToBuy />, <Quality />, <Youtube />, <Remont />, <Nadejno />, <HodStr />, /*<Managers />,*/ <Contacts />] /* <Managers />, <Contacts />  <Youtube2 />*/
   
    
    const handleScroll = (event) => {
        if (loaded.blocks < blocks.length) {
            console.log(event)
            //setTopPos(window.scrollY)
            loaded.setPopup(true)
            loaded.setBlocks(blocks.length)
            window.removeEventListener('scroll', handleScroll, true);
        }
    }
    useEffect(() => {
        if (loaded.blocks < blocks.length) {
            window.addEventListener('scroll', handleScroll, true);
        }
    })

    useEffect(() => {
        scrollToAnkorIfNeeded()
    }, [])

    const scrollToAnkorIfNeeded = () => {
        var hash = window.top.location.hash.substr(1);
        if (hash.length > 1 && loaded.blocks < blocks.length) {
            loaded.setPopup(true)
            loaded.setBlocks(blocks.length)
            setTimeout(()=>{
                try{
                    window.scrollTo({
                        top: document.querySelector("."+hash).offsetTop,
                        behavior: "smooth"
                    })
                } catch(e){}
            },2000)
        }
    }

    return (
        <div className="blocks" data={loaded.menuClick ? "true" : ""}>
            {blocks.map((block, index) => {
                if (index< loaded.blocks) {
                    return <Suspense fallback={<div></div>}>{block}</Suspense>
                }
            })}
            {loaded.popup ? <Suspense fallback={<div></div>}><Popups /></Suspense> : <div></div>}
        </div>
    )

}

export default Loader