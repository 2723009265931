import React, { useEffect, useState, useContext } from 'react'
import { BlocksContext } from "../../context/blocksContext"


export const Menu = () => {
    const [icon, setIcon] = useState(false)
    const [collapseMenu, setCollapseMenu] = useState(false)
    const blocks = useContext(BlocksContext)

  const actionShow = (event) => {
    event.preventDefault()
    if (document.querySelector('.sale').style.display=="none"){
      document.querySelector('.sale').style.display="block"
    } else {
      document.querySelector('.sale').style.display="none"
    }
  }

    const handleScroll = (event)=>{
        if (window.scrollY<30 && !collapseMenu){
            setCollapseMenu(false)
        } else {
            setCollapseMenu(true)
        }
    }
    useEffect(()=> {
        window.addEventListener('scroll', handleScroll, true);
        const interval = setInterval(()=>{
            clearInterval(interval)
            setIcon(!icon)
        }, 5000)
    }, [])

    const showPopup = (e) =>{
        e.preventDefault()
        if (!blocks.popup) {
            blocks.setPopup(true)
            setTimeout(()=>{
                let popup = e.target.getAttribute('data')
                document.querySelector('.pu_rgba').style.display="block"
                document.querySelectorAll('.pu_rgba img').forEach((el)=> {
                    if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
                        el.setAttribute('src', el.getAttribute('data-src'))
                    }
                })
                document.querySelectorAll(".pu_inner").forEach((el)=> el.style.display="none")
                document.querySelector("#"+popup).style.display="block"
                document.querySelector("body").classList.add("overflow")
            },500)
        } else {
            let popup = e.target.getAttribute('data')
                document.querySelector('.pu_rgba').style.display="block"
                document.querySelectorAll('.pu_rgba img').forEach((el)=> {
                    if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
                        el.setAttribute('src', el.getAttribute('data-src'))
                    }
                })
                document.querySelectorAll(".pu_inner").forEach((el)=> el.style.display="none")

                document.querySelector("#"+popup).style.display="block"
                document.querySelector("body").classList.add("overflow")
        }
    }

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("data")
        console.log(popup)

        let num = 20
        if (blocks.blocks<num){
            blocks.setMenuClick(true)
            blocks.setBlocks(num+1)

            setTimeout(()=>{
                window.scrollTo({
                    top: document.querySelector("#"+popup).offsetTop,
                    behavior: "smooth"
                })
                setTimeout(()=>{
                    blocks.setMenuClick(false)
                },500)


            },1000)



        } else {
            window.scrollTo({
                top: document.querySelector("#"+e.currentTarget.getAttribute("data")).offsetTop,
                behavior: "smooth"
            })
        }
        document.querySelector('.hl_nav').classList.remove('hl_nav_act')
        document.querySelector('.hl_nav_list').style.display="none"
    }

    const sandwichClick = (e) => {
        if (document.querySelector('.hl_nav').classList.contains('hl_nav_act')) {
            document.querySelector('.hl_nav').classList.remove('hl_nav_act')
            document.querySelector('.hl_nav_list').style.display="none"
        } else {
            document.querySelector('.hl_nav').classList.add('hl_nav_act')
            document.querySelector('.hl_nav_list').style.display="block"
        }
    }

    const phoneClick = () => {
        try {
            ym(47704273, 'reachGoal', 'getCall');
        } catch(e) {
            console.log(e)
        }
    }

    return(
        <section className="hl_fixed">
            <div className="logo"><img src="img/logo.png" /></div>
            <div onClick={sandwichClick} className="hl_nav">Меню</div>
            <a className="hl_phone roistat_ph_gr" onClick={phoneClick} target="_blank" href="tel:88612795353"><span>8 (861) 279 53 53</span></a>
            <ul style={{display:"none"}} className="hl_nav_list">
              {/* <li><a onClick={actionShow} href="#">Ипотека 0%</a></li> */}
                <li><a onClick={clickMenu} data="plans" href="#">Планировки и цены</a></li>
                <li><a onClick={clickMenu} data="rasp" href="#">Расположение</a></li>
                <li><a onClick={clickMenu} data="opl" href="#">Способы оплаты</a></li>
                <li><a onClick={clickMenu} data="contacts" href="#">Контакты</a></li>
            </ul>
        </section>
    )
}

export default Menu
