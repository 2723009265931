import { BlocksContext } from "./context/blocksContext"
import {useBlocks} from "./hooks/blocks.hook"
import { LoaderMobile } from './components/mobile/loader';
import { React, useState } from 'react';
import { Loader } from './components/loader';


function App() {
  const {blocks, setBlocks, popup, setPopup, menuClick, setMenuClick} = useBlocks()
  /*const Loader = React.lazy(() => import("./components/loader"))
  const LoaderMobile = React.lazy(() => import("./components/mobile/loader"))*/

  return (
    <div className="App container_main"> {/* container_main только для мобилы добавлять */}
      <BlocksContext.Provider value={{
        blocks,
        setBlocks,
        popup,
        setPopup,
        menuClick,
        setMenuClick
      }}>
      {!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent))?<Loader />:<LoaderMobile />}
      </BlocksContext.Provider>
    </div>
  );
}

export default App;
