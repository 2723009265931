import React, {  useContext, useEffect, useState } from 'react'
import { BlocksContext } from "../../context/blocksContext"


export const Header = () => {
	const blocks = useContext(BlocksContext)
	const [title, setTitle] = useState(null);
	const [flagTitle, setFlagTitle] = useState(false);
	const actionShow = (event) => {
		event.preventDefault()
		if (document.querySelector('.sale').style.display == "none") {
			document.querySelector('.sale').style.display = "block"
		} else {
			document.querySelector('.sale').style.display = "none"
		}
	}

	const closeAction = (event) => {
		event.preventDefault()
		document.querySelector('.sale').style.display = "none"
	}

	const getTitle = () => {
		let paths = window.location.toString().split("?")[1]

		let utm = {}
		if (paths == undefined) {
			fetch("https://жк-грани-краснодар.рф/titles.php", {})
				.then(data => data.ok && data.json())
				.then(response => {
					console.log(response.title)
					setTitle(response.title)
				});
		} else {
			if (paths.split('&').length>0){
				let params = paths.split('&')
				let metka = ""
				params.forEach((param)=>{
					param = param.split('=')
					if(param[0]=="utm_medium"){
						console.log(param[1]);
						if (param[1]!=null){
							if (metka !=""){
								metka+="&"
							}
							metka+="utm_medium="+param[1]
						}
					}
					if(param[0]=="utm_source"){
						console.log(param[1]);
						if (param[1]!=null){
							if (param[1]!=null){
								if (metka !=""){
									metka+="&"
								}
								metka+="utm_source="+param[1]
							}
						}
					}
				})
				if (metka!=""){
					fetch("https://жк-грани-краснодар.рф/titles.php?"+metka, {})
									.then(data => data.ok && data.json())
									.then(response => {
										console.log(response.title)
										setTitle(response.title)
									});
				}
			}
		}


		return utm
	}


	if (!flagTitle) {
		getTitle()
		setFlagTitle(true);
	}

	const genTitle = (title) => {
       
        if (!title || title === "") {
            console.log("empty")
            return null
        }
        let titleList = title.split('<br />')
        if (titleList.length>1){
            return <React.Fragment>{titleList[0]}<br />{titleList[1]}</React.Fragment>
        }
        return titleList[0]
    }

	const flatShowCheck = () => {
        try {
            let flatID = null;
            if (window.location.toString().indexOf('flatID')===-1) {
                return
            }
            let params = window.location.toString().split("?")[1];
            let paramItems = params.split('&')
            paramItems.forEach((item) => {
                if (item.split("=")[0] === 'flatID') {
                    flatID = item.split("=")[1]
                }
            })
            if (flatID) {
                console.log(flatID);
                blocks.setBlocks(14)
                blocks.setPopup(true)
                const headers = { 'Content-Type': 'application/json' }
                let id = parseInt(flatID)
                fetch("https://жк-грани-краснодар.рф/flats_react.php?id="+id, headers)
                    .then(res => res.json())
                    .then((el)=>{
                        let popup = "pu_flat"
                        document.querySelector('.pu_rgba').style.display="block"
                        document.querySelector('body').classList.add('overflow')
                        document.querySelector('#popup_flat').style.display="block"
                        document.querySelector('#popup_flat .pu_title').innerHTML=el.name
                        document.querySelector('#popup_flat .pu_flat_char').innerHTML=el.properties
                        document.querySelector('#popup_flat .pu_flat_right img').setAttribute('src',"https://жк-грани-краснодар.рф/" + el.img)
                        document.querySelector('#popup_flat input[type="hidden"]').value = "Узнать стоимость: Литер: "+el.liter+", "+el.name+"; Жилая площадь: "+el.zhil+" Общая площадь: "+el.obsh
                    })
            }
        } catch (e) {
            console.log(e)
        }
    }

	useEffect(()=>{
		flatShowCheck()
	},[])


	return (

		<section className="header">
			{/* <div className="ny_dec"><img src="img/hd_dec.png" alt=""></div>*/}
			<div className="hwrap">
				<div className="wmain">
					<div className="hw_dec"></div>
					<div className="hr_txt">г. Краснодар, Западный обход, 45</div>
					{genTitle(title)===null?
                            <h2>
                                2-комнатные квартиры <br /> комфорт-класса от 4,6 млн
                            </h2>:
                            <h2>
                                {genTitle(title)}
                            </h2>
                        }
					{/* <div className="h_sub_dec">от застройщика с <br /> безупречной репутацией</div> */}
				</div>
				<div className="h_advant">
					<p>
						<span>5 – 7 квартир на этаже</span>
						<span>Остекленные балконы</span>
						<span>Скоростные лифты</span>
						<span>Кладовки и колясочные</span>
					</p>
				</div>
			</div>
			{/*<div className="marquiz__container">
                <a className="marquiz__button marquiz__button_blicked marquiz__button_rounded marquiz__button_shadow marquiz__button_fixed marquiz__button_fixed-left" href="#popup:marquiz_5cfa56cf2dfe2600448c050d" data-fixed-side="left" data-alpha-color="rgba(255, 0, 0, 0.5)" data-color="#ff0000" data-text-color="#ffffff">Получите подборку квартир</a>
			</div>*/}
		</section>
	)
}

export default Header