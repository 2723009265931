import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const BeSide = () =>{
    return (
        <section className="beside">
            <div className="wmain">
                <ul className="beside_list">
                    <li>
                        <div className="bl_l">
                            <div className="bl_info">
                                <div className="bl_title">
                                    Всё рядом
                                    <span>
                                        В жилом комплексе «Грани» будет расположена вся необходимая инфраструктура
                                    </span>
                                </div>
                                <div className="bl_img">
                                    <img className="lazy" src="img/beside_list_img0.jpg" />
                                </div>
                            </div>
                        </div>
                        <div className="bl_r">
                            <div className="bl_inner">
                                <div className="bl_content">
                                    <div className="bl_ico">
                                        <img className="lazy" src="img/beside_ico1.png" />
                                    </div>
                                    2500 м<sup>2</sup> будут занимать
                                    <span>
                                        супермаркет, магазины, аптеки, <br />салоны красоты, банкоматы и т.п.
                                    </span>
                                </div>
                                <div className="bl_img">
                                    <img className="lazy" src="img/beside2_list_img_01.jpg" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="bl_r">
                            <div className="bl_inner">
                                <div className="bl_content bl_2">
                                    <div className="bl_ico">
                                        <img className="lazy" src="img/beside_ico3.png" />
                                    </div>
                                    современные <br />детские площадки
                                    <span>
                                        {/*Используется каучуковое, <br />травмобезопасное покрытие.*/}
                                    </span>
                                </div>
                                <div className="bl_img">
                                    <img className="lazy" src="img/beside2_list_img_02.jpg" />
                                </div>
                            </div>
                            <div className="bl_inner">
                                <div className="bl_content bl_3">
                                    <div className="bl_ico">
                                        <img className="lazy" src="img/beside_ico4.png" />
                                    </div>
                                    1417 парковочных мест
                                    <span>
                                        Для требовательных автовладельцев<br /> предусмотрен крытый паркинг<br /> Велопарковка у каждого подъезда
                                    </span>
                                </div>
                                <div className="bl_img">
                                    <img className="lazy" src="img/beside2_list_img_04_01.jpg" />
                                </div>
                            </div>
                        </div>
                        <div className="bl_l">
                            <div className="bl_inner">
                                <div className="bl_content bl_1">
                                    <div className="bl_ico">
                                        <img className="lazy" src="img/beside_ico2.png" />
                                    </div>
                                    Спортивные зоны и тренажеры <br />на открытом воздухе
                                    <span>
                                        Размещены на эксплуатируемой кровле <br />коммерческих помещений. Гарантированная <br />защита от наезда автомобилей.
                                    </span>
                                </div>
                                <div className="bl_img">
                                    <img className="lazy" src="img/beside2_list_img_03.jpg" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="bl_content bl_1">
                            <div className="bl_ico">
                                <img className="lazy" src="img/beside_ico5.png" />
                            </div>
                            Зона отдыха с фонтанами
                            <span>
                                Место, где можно насладиться <br /> тишиной и спокойствием
                            </span>
                        </div>
                        <img className="lazy" src="img/beside2_list_img_04.jpg" />
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default BeSide