import React, { useEffect, useState, useContext, Suspense} from "react"
import { BlocksContext } from "../../context/blocksContext"
import { BeSide } from './beside';
import { Menu } from './menu';
import { Header } from './header';
// import "../../css/style_m_0720.css" 


export const LoaderMobile = () => {
    import("../../css/style_m_0720.css");
    const loaded = useContext(BlocksContext)
     
     

    const Profit = React.lazy(() => import("./profit"))
    // const Action = React.lazy(() => import("../action"))
    const Popups = React.lazy(() => import("./popups"))
    const BestOffer = React.lazy(() => import("../bestOffer"))
    const GetPodbor = React.lazy(() => import("./getPodbor"))
    const HowToBuy = React.lazy(() => import("./howToBuy"))
    const Plans = React.lazy(() => import("./plans"))
    const Comfort = React.lazy(() => import("./comfort"))
    const About = React.lazy(() => import("./about"))
    const HodStr = React.lazy(() => import("./hodStr"))
    const Contacts = React.lazy(() => import("./contacts"))
    const Footer = React.lazy(() => import("./footer"))
    const Rasp = React.lazy(() => import("./rasp"))
    



    const blocks = [<Menu />, <Header />, /*<Action />,*/ <BeSide />, <Profit />, <Plans />, <BestOffer />, <Comfort />, <GetPodbor />, <Rasp />, <HowToBuy />, <About />, <HodStr />, <Contacts />, <Footer />]
    const LoadBlock = (block) => {
        return <Suspense fallback={<div>Загрузка...</div>}>{block}</Suspense>
    }

    const generateHtml = () =>{
        let toDraw = []
        for(let i=0;i<loaded.blocks; i++){
            toDraw.push(LoadBlock(blocks[i]))
        }
        return (
            <div className="blocks" data={loaded.menuClick?"true":""}>
                {loaded.popup?<Suspense fallback={<div>Загрузка...</div>}><Popups /></Suspense>:""}
                {toDraw.map((elem)=>{return elem})}
            </div>
        )
    }
    
    const handleScroll = (event) => {
        if (loaded.blocks < blocks.length) {
            console.log(loaded.blocks)
            loaded.setBlocks(blocks.length)
            loaded.setPopup(true)
            window.removeEventListener('scroll', handleScroll, true);
        }
    }

    useEffect(()=> {
        if (loaded.blocks<blocks.length){
            window.addEventListener('scroll', handleScroll, true);
        }
    }, [])

    useEffect(() => {
        scrollToAnkorIfNeeded()
    }, [])

    const scrollToAnkorIfNeeded = () => {
        var hash = window.top.location.hash.substr(1);
        if (hash.length > 1 && loaded.blocks < blocks.length) {
            loaded.setPopup(true)
            loaded.setBlocks(blocks.length)
            setTimeout(()=>{
                try{
                    window.scrollTo({
                        top: document.querySelector("."+hash).offsetTop,
                        behavior: "smooth"
                    })
                } catch(e){}
            },2000)
        }
    }

    return generateHtml()
    
}

export default LoaderMobile