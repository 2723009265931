import React,{ useEffect, useState} from 'react'
import InputMask from 'react-input-mask'
import { useSendForm } from '../hooks/send-from.hook';

export const Header = () => {
    const sendForm = useSendForm();
    const [title,setTitle] = useState("");
    const [flagTitle,setFlagTitle] = useState(false);
	const actionShow = (event) => {
		event.preventDefault()
		if (document.querySelector('.sale').style.display=="none"){
			document.querySelector('.sale').style.display="block"
		} else {
			document.querySelector('.sale').style.display="none"
		}
	}

	const closeAction = (event) => {
		event.preventDefault()
		document.querySelector('.sale').style.display="none"
	}



    function isDescendant(child) {
        var node = child.parentNode;
        while (node.classList != undefined) {
            if (node.classList.contains('pu_inner') || node.classList.contains('sale')) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    useEffect(()=>{
        document.querySelector('.blocks').addEventListener('click', (e)=>{
            let clicked = e.target
            console.log(clicked)
            if (!clicked.classList.contains('pu_inner') && !clicked.classList.contains('sale') && !clicked.classList.contains('arrow-img') && !isDescendant(clicked) && e.target.nodeName.toLowerCase()!='canvas'){
                document.querySelector('body').classList.remove('overflow')
                if (document.querySelector(".pu_rgba")!==null) {
                    document.querySelector(".pu_rgba").style.display="none"
                }
                if (document.querySelector(".pu_inner")!==null) {
                    document.querySelector(".pu_inner").style.display="none"
                }
                if ( document.querySelector(".sale")!=null){
                    document.querySelector(".sale").style.display="none";
                }
            }
        })
    },[])

    const getTitle = () =>{
        let paths = window.location.toString().split("?")[1]

        let utm={}
        if (paths==undefined){
            fetch("https://жк-грани-краснодар.рф/titles.php", {})
                .then(data => data.ok && data.json())
                .then(response => {
                    console.log(response.title)
                    setTitle(response.title)
                });
        } else {
        if (paths.split('&').length>0){
            let params = paths.split('&')
            let metka = ""
            params.forEach((param)=>{
                param = param.split('=')
                if(param[0]=="utm_medium"){
                    console.log(param[1]);
					if (param[1]!=null){
                        if (metka !=""){
                            metka+="&"
                        }
                        metka+="utm_medium="+param[1]
					}
                }
                if(param[0]=="utm_source"){
                    console.log(param[1]);
					if (param[1]!=null){
                        if (param[1]!=null){
                            if (metka !=""){
                                metka+="&"
                            }
                            metka+="utm_source="+param[1]
                        }
					}
                }
            })
            if (metka!=""){
                fetch("https://жк-грани-краснодар.рф/titles.php?"+metka, {})
                                .then(data => data.ok && data.json())
                                .then(response => {
                                    console.log(response.title)
                                    setTitle(response.title)
                                });
            }
        }}


        return utm
    }


	if (!flagTitle){
		getTitle()
        setFlagTitle(true);
	}

    const genTitle = (title) => {

        if (!title || title === "") {
            console.log("empty")
            return null
        }
        let titleList = title.split('<br />')
        if (titleList.length>1){
            return <React.Fragment>{titleList[0]}<br />{titleList[1]}</React.Fragment>
        }
        return titleList[0]
    }




    return (
		<div className="none">
			<div className="header h100 block_decor" style={{height:"100vh"}}>
				<div className="hwrap">
					<div className="wmain">
					<div className="hw_dec"></div>
						<div className="hr_txt">г. Краснодар, Западный обход, 45</div>
						{genTitle(title)===null?
                            <h2>
                                2-комнатные квартиры <br /> комфорт-класса от 4,6 млн
                            </h2>:
                            <h2>
                                {genTitle(title)}
                            </h2>
                        }
						{/* <div className="h_sub_dec">от застройщика с <br /> безупречной репутацией</div> */}
					</div>
					<div className="h_advant">
						<p>
							<span>5 – 7 квартир на этаже</span>
							<span>Остекленные балконы</span>
							<span>Скоростные лифты</span>
							<span>Кладовки и колясочные</span>
						</p>
					</div>
				</div>
			</div>
			<div className="all_comp" id="all_comp">
				<div className="sale" style={{display:"none"}}>
					<a onClick={closeAction} className="closeform closeform2 close_akc" href="#"></a>
					<img src="img/grani_ipot_0109.jpg" alt="" />
					<form className="form_style">
						<div className="tm_02">
                            Заполните заявку, и мы свяжемся с вами
						</div>
						<span>
							<input name="name" className="in_name name ym-record-keys" type="text" placeholder="Ваше имя" data="Ваше имя" />
						</span>
						<span>
							<InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null} >
								<input name="phone" className="in_phone phone ym-record-keys" type="text" placeholder="Ваш телефон" req="y" />
							</InputMask>
						</span>
						<input type="hidden" className="text" value="Ипотека от 0%" />
						<button className="btn_form btn_form_calc lead-btn" onClick={sendForm.sendForm} template="1" celtype="getAction">Отправить</button>
					</form>
				</div>
				{/* <a className="btn_block" onClick={actionShow} href="#">Ипотека 0%</a> */}
            </div>
		</div>
    )
}

export default Header
