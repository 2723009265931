import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const BeSide = () =>{
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={"slick-next slick-arrow"}
                style={{ ...style, display: "block", background: "red", width:"50px", height:"50px" }}
                onClick={onClick}
            />
        );
    }
    
    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "block", background: "green", width:"50px", height:"50px" }}
            onClick={onClick}
            />
        );
    }
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <section className="beside">
            <div className="tm">
                Все рядом
            </div>
             <Slider className="beside_slider" {...settings}>
                <div>
                    <div className="bs_inner">
                        <div className="bs_img">
                            <img src="img_mob/beside_img01.jpg" />
                            <div className="bs_ico"><img src="img_mob/beside_ico1.png" /></div>
                        </div>
                        <div className="bs_info">
                            2500 м<sup>2</sup> будут занимать
                            <span>
                                супермаркет, магазины, аптеки, салоны красоты, банкоматы и т.п.
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="bs_inner">
                        <div className="bs_img">
                            <img src="img_mob/beside_img03.jpg" />
                            <div className="bs_ico"><img src="img_mob/beside_ico3.png" /></div>
                        </div>
                        <div className="bs_info">
                            современные <br/> детские площадки
                            <span>
                                {/*Используется каучуковое, травмобезопасное покрытие.*/}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="bs_inner">
                        <div className="bs_img">
                            <img src="img_mob/beside_img02.jpg" />
                            <div className="bs_ico"><img src="img_mob/beside_ico2.png" /></div>
                        </div>
                        <div className="bs_info">
                            Спортивные зоны и тренажеры <br /> на открытом воздухе
                            <span>
                                Размещены на эксплуатируемой кровле коммерческих помещений. Гарантированная защита от наезда автомобилей.
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="bs_inner">
                        <div className="bs_img">
                            <img src="img_mob/beside_img04.jpg" />
                            <div className="bs_ico"><img src="img_mob/beside_ico4.png" /></div>
                        </div>
                        <div className="bs_info">
                            ОБЩАЯ ПЛОЩАДЬ ПАРКИНГА 860 ГА ИЛИ 12 ФУТБОЛЬНЫХ ПОЛЕЙ
                            <span>
                                Для требовательных автовладельцев<br /> предусмотрен крытый паркинг<br /> Велопарковка у каждого подъезда
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="bs_inner">
                        <div className="bs_img">
                            <img src="img_mob/beside_list_img_5.jpg" />
                            <div className="bs_ico"><img src="img_mob/beside_ico5.png" /></div>
                        </div>
                        <div className="bs_info">
                        Зона отдыха с фонтанами
                            <span>
                                Для требовательных автовладельцев<br /> предусмотрен крытый паркинг<br /> Велопарковка у каждого подъезда
                            </span>
                        </div>
                    </div>
                </div>
            </Slider>
        </section>
    )
}

export default BeSide