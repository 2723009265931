import React, { useEffect, useState, useContext } from 'react'
import { BlocksContext } from "../context/blocksContext"



export const Menu = () => {
    const [icon, setIcon] = useState(false)
    const [collapseMenu, setCollapseMenu] = useState(false)
    const blocks = useContext(BlocksContext)

  const actionShow = (event) => {
    event.preventDefault()
    if (document.querySelector('.sale').style.display=="none"){
      document.querySelector('.sale').style.display="block"
    } else {
      document.querySelector('.sale').style.display="none"
    }
  }

    const handleScroll = (event)=>{
        if (window.scrollY<30 && !collapseMenu){
            setCollapseMenu(false)
        } else {
            setCollapseMenu(true)
        }
    }

    const flatShowCheck = () => {
        try {
            let flatID = null;
            if (window.location.toString().indexOf('flatID')===-1) {
                return
            }
            let params = window.location.toString().split("?")[1];
            let paramItems = params.split('&')
            paramItems.forEach((item) => {
                if (item.split("=")[0] === 'flatID') {
                    flatID = item.split("=")[1]
                }
            })
            if (flatID) {
                console.log(flatID);
                blocks.setBlocks(22)
                blocks.setPopup(true)
                const headers = { 'Content-Type': 'application/json' }
                let id = parseInt(flatID)
                fetch("https://жк-грани-краснодар.рф/flats_react.php?id="+id, headers)
                        .then(res => res.json())
                        .then((el)=>{
                            let popup = "pu_flat"
                            setTimeout(()=>{
                                document.querySelector('.pu_rgba').style.display="block"
                                document.querySelector('body').classList.add('overflow')
                                document.querySelector('#popup_flat').style.display="block"
                                document.querySelector('#popup_flat .pu_flat_title').innerHTML=el.name
                                document.querySelector('#popup_flat .pu_flat_char').innerHTML=el.properties
                                document.querySelector('#popup_flat .pu_flat_r img').setAttribute('src', "https://жк-грани-краснодар.рф/"+el.img)
                                document.querySelector('#popup_flat input[type="hidden"]').value = "Узнать стоимость: Литер: "+el.liter+", "+el.name+"; Жилая площадь: "+el.zhil+" Общая площадь: "+el.obsh
                            }, 10)

                        })
            }
        } catch (e) {

        }
    }
    useEffect(()=> {
        flatShowCheck()
        window.addEventListener('scroll', handleScroll, true);
        const interval = setInterval(()=>{
            clearInterval(interval)
            setIcon(!icon)
        }, 5000)
    }, [])

    const showPopup = (e) =>{
        e.preventDefault()
        if (!blocks.popup) {
            blocks.setPopup(true)
            setTimeout(()=>{
                let popup = e.target.getAttribute('data')
                document.querySelector('.pu_rgba').style.display="block"
                document.querySelectorAll('.pu_rgba img').forEach((el)=> {
                    if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
                        el.setAttribute('src', el.getAttribute('data-src'))
                    }
                })
                document.querySelectorAll(".pu_inner").forEach((el)=> el.style.display="none")
                document.querySelector("#"+popup).style.display="block"
                document.querySelector("body").classList.add("overflow")
            },500)
        } else {
            let popup = e.target.getAttribute('data')
                document.querySelector('.pu_rgba').style.display="block"
                document.querySelectorAll('.pu_rgba img').forEach((el)=> {
                    if (el.getAttribute('src') == null && el.getAttribute('data-src')!=null){
                        el.setAttribute('src', el.getAttribute('data-src'))
                    }
                })
                document.querySelectorAll(".pu_inner").forEach((el)=> el.style.display="none")

                document.querySelector("#"+popup).style.display="block"
                document.querySelector("body").classList.add("overflow")
        }
    }

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("data")

        let num = 23


        if (blocks.blocks<num){
            console.log(popup)
            blocks.setMenuClick(true)
            blocks.setBlocks(num+1)
            setTimeout(()=>{
                window.scrollTo({
                    top: document.querySelector("#"+popup).offsetTop,
                    behavior: "smooth"
                })
            },2000)



        } else {
            window.scrollTo({
                top: document.querySelector("#"+e.currentTarget.getAttribute("data")).offsetTop,
                behavior: "smooth"
            })
        }
    }

    const phoneClick = () => {
        try {
            ym(47704273, 'reachGoal', 'getCall');
        } catch(e) {
            console.log(e)
        }
    }

    return(
        <div className="hl_top">
            <div className="wmain">
                <a className="logo" href="#"><img src="img/logo.png" /></a>
                <div className="hamburger hamburger--stand">
                    <div className="hamburger-box">
                        <div className="hamburger-inner"></div>
                    </div>
                </div>
                <ul className="hl_nav">
                    {/* <li><a onClick={actionShow} href="#">Ипотека 0%</a></li> */}
                    <li><a num="5" onClick={clickMenu} data="ankor1" href="#">Планировки и цены</a></li>
                    <li><a num="9" onClick={clickMenu} data="ankor2" href="#">Расположение</a></li>
                    <li><a num="14" onClick={clickMenu} data="ankor3" href="#">Способы оплаты</a></li>
                    <li><a num="22" onClick={clickMenu} data="ankor4" href="#">Контакты</a></li>
                </ul>
                <div className="hl_right">
                    <a className="hl_phone roistat_ph_gr" onClick={phoneClick} target="_blank" href="tel:88612795353">8 (861) 279 53 53</a>
                    <a className="hl_call show_popup" data="popup_call" href="#" onClick={showPopup}>заказать звонок</a>
                </div>
            </div>
	    </div>
    )
}

export default Menu
